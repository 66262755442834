import styled from 'styled-components';
import swatches from '../utils/swatches';

export default styled.div`
  max-width: 600px;
  margin: 40px auto 0;
  p {
    padding: 0 15px;
  }
  a {
    color: ${swatches.red};
  }
  img {
    max-width: 100%;
    width: 590px;
  }
`;