import React, { Fragment } from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Header from '../components/Header';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import Email from '../components/Email';
import { formatReadingTime } from '../utils/helpers';
import moment from 'moment';

const StyledContainer = styled.div`
  padding: 25px;
  h2 {
    margin: 0;
  }
`;

const StyledEmailEntry = styled.div`
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledEmailTitle = styled.h3`
  padding: 0 15px;
  font-family: brother-1816, sans-serif;
`;

const concantenatePosts = (posts) => {
  if (!posts || !posts.length) return null;
  const getDate = (p) => moment(get(p, 'node.frontmatter.date'), "MMMM DD, YYYY");
  const latestPost = getDate(posts[0]);
  const blogPosts = posts.filter(p => getDate(p).isSame(latestPost, 'day'));
  const timeToRead = blogPosts.reduce((time, next) => get(next, 'node.timeToRead') + time, 0);
  const date = get(blogPosts[0], 'node.frontmatter.date');
  const image = get(blogPosts[0], 'node.frontmatter.image');
  const entries = blogPosts.reduce((acc, next) => {
    const entry = {
      title: get(next, 'node.frontmatter.title'),
      html: get(next, 'node.html')
    };
    return [
      ...acc,
      entry
    ];
  },[]);
  return {
    timeToRead,
    date,
    image,
    entries,
  };
};

class BlogIndex extends React.Component {
  renderPost() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges');
    const blogPosts = posts.filter(p => get(p, 'node.frontmatter.spoiler'));
    const blogPost = concantenatePosts(blogPosts);
    return (
      <StyledContainer>
        <h2>This week on HealthFarm</h2>
        <hr />
        <small>
          {blogPost.date}
          {` • ${formatReadingTime(blogPost.timeToRead)}`}
        </small>
        <Email>
          {
            blogPost.entries.map(e => (
              <Fragment key={e.title}>
                <StyledEmailTitle>{e.title}</StyledEmailTitle>
                <StyledEmailEntry dangerouslySetInnerHTML={{ __html: e.html }} />
              </Fragment>
            ))
          }
        </Email>
      </StyledContainer>
    )
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Fragment>
        <SEO />
        <Header />
        <Layout header footer location={this.props.location} title={siteTitle}>
          { this.renderPost() }
        </Layout>
        <Footer />
      </Fragment>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          html
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
            image
          }
        }
      }
    }
  }
`;
