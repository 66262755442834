import React from 'react';
import styled from 'styled-components';

import mailbox from './mailbox.svg';
import chevron from './chevron.svg';
import Subscribe from './Subscribe';
import Icon from './Icon';

const StyledLayout = styled.main`
  padding: 90px 5px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledBlurb = styled.main`
  max-width: 600px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  svg {
    width: 50px;
    height: 60px;
  }
  h3, p {
    margin: 10px 0;
  }
  p {
    cursor: pointer;
  }
`;

const StyledIcon = styled.span`
  display: inline-flex;
  align-items: center;
  svg {
    margin-left: 10px;
    width: 0.9rem;
    height: 0.6rem;
  }
`;

const EmailFooter = styled.section`
  margin-top: -50px;
  background-image: url(/footer.svg);
  background-position: left bottom;
  height: 435px;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 10px 0;
  width: 100%;
  svg {
    width: 50px;
    height: 60px;
  }
`;

const StyledContainer = styled.main`
  max-width: 800px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1);
  margin-bottom: 60px;
`;

class Layout extends React.Component {

  emailFooter = React.createRef();
  
  state = {
    focus: false,
  };
 
  renderEmailHeader() {
    if (!this.props.header) return null;
    const scrollToEmail = () => { 
      this.emailFooter.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
      setTimeout(() => {
        this.setState({ focus: true }, () => this.setState({ focus: false }));
      }, 500);
    };
    return (
      <StyledBlurb>
        <Icon icon={mailbox} />
        <h3>Each week we send you an email containing bite-sized news stories and perspective to help you make quick and lasting improvements to your health and wellness. Here’s our latest email.</h3>
        <p onClick={scrollToEmail}>
          <b>Sign up at the bottom of this page</b>
          <StyledIcon>
            <Icon icon={chevron} stroke="currentColor" />
          </StyledIcon>
        </p>
      </StyledBlurb>
    )
  }

  renderEmailFooter() {
    if (!this.props.footer) return null;
    return (
      <EmailFooter ref={this.emailFooter}>
        <Icon icon={mailbox} />
        <h3>Sign up for your weekly shot of <br/>news.</h3>
        <Subscribe focus={this.state.focus} />
      </EmailFooter>
    );
  }

  render() {
    const { children } = this.props

    return (
      <StyledLayout>
        { this.renderEmailHeader() }
        <StyledContainer>
          { children }
          { this.renderEmailFooter() }
        </StyledContainer>
      </StyledLayout>
    )
  }
}

export default Layout
